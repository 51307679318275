<template>
  <div class="comment-page">
    <no-data v-if="List.length==0"></no-data>
    <van-pull-refresh  v-model="isloading" @refresh="onRefresh" v-else>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" >
        <div class="comment-wrap van-cell"  v-for="(item,index) in List" :key="index">
           <div class="left">
             <van-image   round width="1.2rem" height="1.2rem" lazy-load :src="item.avatar" />
           </div>
           <div class="right ml10">
              <div class="name text-base font-16 clamp-1">{{item.nickname}}</div>
              <div class="time text-base font-12 clamp-1 text-grey ">{{item.createtime}}</div>
              <div class="content text-base font-14 clamp-2">{{item.content}}</div>
           </div>
      </div>
    </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, Cell, PullRefresh } from "vant";
import {
    comment_list
} from "@/request/api";

export default {
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [PullRefresh.name]:PullRefresh
    
  },
  data() {
    return {
      List:[],
      page:1,
      loading: false,
      finished: false,
      isloading:false,
      activity_id:0,
    };
  },
  methods: {
      async loadData() {
          if(this.activity_id<0){
              this.$toast.fail("活动已经结束");return;
          }
          // 获取评论列表
          await comment_list({activity_id: this.activity_id,page: this.page}).then(res => {
              console.log(res);
              if(res.code == 1){

                  //  还有更多数据page++
                  if (res.data.has_more == true){
                      this.page++
                      this.finished = false
                  } else {
                      //没有数据了
                      this.finished = true
                  }
                  this.List = this.List.concat(res.data.data)
              } else {
                  this.$dialog({message:res.msg});
              }
          }).catch(err => {
              this.$dialog({
                  message: "评论数据获取失败，请重新刷新"
              })
          })

          // 加载状态结束
          this.loading = false;
      },
     onRefresh(){
         this.page = 1;
         this.finished = false;
         this.List = [];
         this.loadData();
       },
  },
  mounted: function() {
    this.activity_id = this.$route.params.activity_id;
    this.loadData();
  }

};
</script>

<style lang="less" >
   //评论
   .comment-page{
       .comment-wrap{
    display: flex;
    flex: row nowrap;
    align-content: center;
    .left{
      align-items: center;
    }
    .right{
       display: flex;
       flex-flow: column nowrap;
    }
  }

   }

</style>>

